import {
    REQUEST_SUBMIT_ARCHIVE_DATA,
    SUCCESS_SUBMIT_ARCHIVE_DATA,
    FAILURE_SUBMIT_ARCHIVE_DATA,
    REQUEST_GET_LIST_ARCHIVE,
    SUCCESS_GET_LIST_ARCHIVE,
    FAILURE_GET_LIST_ARCHIVE,
    REQUEST_UPDATE_ARCHIVE,
    SUCCESS_UPDATE_ARCHIVE,
    FAILURE_UPDATE_ARCHIVE,
    REQUEST_FILE_DOWNLOAD_ARCHIVE,
    SUCCESS_FILE_DOWNLOAD_ARCHIVE,
    FAILURE_FILE_DOWNLOAD_ARCHIVE,

    // UPDATE_SESSION_EXPIRY,
} from "../Constant/archive";


export const requestSubmitArchiveData = () => ({
    type: REQUEST_SUBMIT_ARCHIVE_DATA,
})
export const submitArchiveDataSuccess = (data) => ({
    type: SUCCESS_SUBMIT_ARCHIVE_DATA,
    payload: data,
})
export const submitArchiveDataFailure = (error) => ({
    type: FAILURE_SUBMIT_ARCHIVE_DATA,
    payload: error,
})

export const requestGetListArchive = () => ({
    type: REQUEST_GET_LIST_ARCHIVE,
})
export const getListArchiveSuccess = (data) => ({
    type: SUCCESS_GET_LIST_ARCHIVE,
    payload: data,
})
export const getListArchiveFailure = (error) => ({
    type: FAILURE_GET_LIST_ARCHIVE,
    payload: error,
})

export const requestUpdateArchive = () => ({
    type: REQUEST_UPDATE_ARCHIVE,
})

export const updateArchiveSuccess = (data) => ({
    type: SUCCESS_UPDATE_ARCHIVE,
    payload: data,
})

export const updateArchiveFailure = (error) => ({
    type: FAILURE_UPDATE_ARCHIVE,
    payload: error,
})

export const requestFileDownloadArchive = () => ({
    type: REQUEST_FILE_DOWNLOAD_ARCHIVE,
})
export const fileDownloadArchiveSuccess = (data) => ({
    type: SUCCESS_FILE_DOWNLOAD_ARCHIVE,
    payload: data,
})
export const fileDownloadArchiveFailure = (error) => ({
    type: FAILURE_FILE_DOWNLOAD_ARCHIVE,
    payload: error,
})


// export const updateSessionExpiry = (nextDay) => ({
//     type: UPDATE_SESSION_EXPIRY,
//     payload: nextDay,
// })
