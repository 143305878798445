import { combineReducers } from "redux";
import {
    REQUEST_SUBMIT_ARCHIVE_DATA,
    SUCCESS_SUBMIT_ARCHIVE_DATA,
    FAILURE_SUBMIT_ARCHIVE_DATA,
    REQUEST_GET_LIST_ARCHIVE,
    SUCCESS_GET_LIST_ARCHIVE,
    FAILURE_GET_LIST_ARCHIVE,
    REQUEST_UPDATE_ARCHIVE,
    SUCCESS_UPDATE_ARCHIVE,
    FAILURE_UPDATE_ARCHIVE,
    REQUEST_FILE_DOWNLOAD_ARCHIVE,
    SUCCESS_FILE_DOWNLOAD_ARCHIVE,
    FAILURE_FILE_DOWNLOAD_ARCHIVE,
} from "../Constant/archive";

const initialState = {
    archiveData: {
        success: '',
        error: "",
    },
    listArchive: [],
    fileDownloadArchive: {
        success: '',
        error: "",
    },
    archiveUpdate: {
        success: '',
        error: "",
    },
    error: "",
    loading: false,
    isFetching: false,
}

const dataArchive = (state = initialState.archiveData, action) => {
    switch (action.type) {

        case REQUEST_SUBMIT_ARCHIVE_DATA:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_SUBMIT_ARCHIVE_DATA:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_SUBMIT_ARCHIVE_DATA:
            return {
                ...state,
                success: '',
                error: action.payload,
            }


        default:
            return state;
    }
};

const listArchiveData = (state = initialState, action) => {
    switch (action.type) {

        case REQUEST_GET_LIST_ARCHIVE:
            return {
                ...state,
                listArchive: [],
                error: '',
            }
        case SUCCESS_GET_LIST_ARCHIVE:
            return {
                ...state,
                listArchive: action.payload,
                error: '',
            }
        case FAILURE_GET_LIST_ARCHIVE:
            return {
                ...state,
                listArchive: [],
                error: action.payload,
            }

        default:
            return state;
    }
};

const downloadArchiveFile = (state = initialState.fileDownloadArchive, action) => {
    switch (action.type) {

        case REQUEST_FILE_DOWNLOAD_ARCHIVE:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_FILE_DOWNLOAD_ARCHIVE:
            return {
                ...state,
                // success: action.payload.message,
                success: action.payload,
                error: '',
            }
        case FAILURE_FILE_DOWNLOAD_ARCHIVE:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const updateArchive = (state = initialState.archiveUpdate, action) => {
    switch (action.type) {

        case REQUEST_UPDATE_ARCHIVE:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_UPDATE_ARCHIVE:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_UPDATE_ARCHIVE:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const isFetching = (state = initialState.isFetching, action) => {
    switch (action.type) {
        case REQUEST_SUBMIT_ARCHIVE_DATA:
        case REQUEST_GET_LIST_ARCHIVE:
        case REQUEST_UPDATE_ARCHIVE:
        case REQUEST_FILE_DOWNLOAD_ARCHIVE:
            return true;

        case SUCCESS_SUBMIT_ARCHIVE_DATA:
        case SUCCESS_GET_LIST_ARCHIVE:
        case SUCCESS_UPDATE_ARCHIVE:
        case SUCCESS_FILE_DOWNLOAD_ARCHIVE:
        case FAILURE_SUBMIT_ARCHIVE_DATA:
        case FAILURE_GET_LIST_ARCHIVE:
        case FAILURE_UPDATE_ARCHIVE:
        case FAILURE_FILE_DOWNLOAD_ARCHIVE:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    dataArchive,
    listArchiveData,
    downloadArchiveFile,
    updateArchive,
    isFetching,
});

export const selectDataArchive = state => state.dataArchive;

export const selectListArchive = state => state;
export const selectUpdateArchive = state => state.updateArchive;
export const selectDownloadArchiveFile = state => state.downloadArchiveFile;



// export const selectLine = state => state.lines;
// export const selectNewLine = state => state.lines;
// export const selectTranslatedLine = state => state.lines;
