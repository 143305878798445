
export const REQUEST_GET_LIST_DOCUMENTS = "REQUEST_GET_LIST_DOCUMENTS";
export const SUCCESS_GET_LIST_DOCUMENTS = "SUCCESS_GET_LIST_DOCUMENTS";
export const FAILURE_GET_LIST_DOCUMENTS = "FAILURE_GET_LIST_DOCUMENTS";

export const REQUEST_UPDATE_DOCUMENT = "REQUEST_UPDATE_DOCUMENT";
export const SUCCESS_UPDATE_DOCUMENT = "SUCCESS_UPDATE_DOCUMENT";
export const FAILURE_UPDATE_DOCUMENT = "FAILURE_UPDATE_DOCUMENT";

export const REQUEST_FILE_DOWNLOAD = "REQUEST_FILE_DOWNLOAD";
export const SUCCESS_FILE_DOWNLOAD = "SUCCESS_FILE_DOWNLOAD";
export const FAILURE_FILE_DOWNLOAD = "FAILURE_FILE_DOWNLOAD";

