import React, { useEffect, useState } from "react";
import { Box, Input, List, ListItem, Button, Tag, TagLabel, TagCloseButton } from "@chakra-ui/react";

import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";

import { useSelector, useDispatch } from "react-redux";
import { selectToken, selectListTags, selectUpdateTags } from "../Redux/Reducer";
import { createTag, getListTagsData, tagsUpdateSubmit } from "../Redux/Thunk/Tags";

import toast from 'react-hot-toast';

const SearchBarWithSuggestions = ({embedded,selectedTags, setSelectedTags}) => {

	console.log("inside search bar with suggestions");
	console.log(selectedTags);

	const { innerHeight } = window;

	const dispatch = useDispatch();

	const tags = useSelector(state => selectListTags(state));
	const token = useSelector(state => selectToken(state));
//	const data = useSelector(state => selectUpdateTags(state));

	const [filteredOptions, setFilteredOptions] = useState(tags);
	const [dataTags, setDataTags] = useState([]);
//	const [selectedTags, setSelectedTags] = useState([]);

	useEffect(() => {
		dispatch(getListTagsData(0, 25, token));
	}, [dispatch, token]);

	
	useEffect(() => {
		setFilteredOptions(tags);
	}, [tags]);
	
	const [inputValue, setInputValue] = useState("");

	const handleInputChange = (e) => {
		const value = e.target.value;
		setInputValue(value);

		if (value.length > 0) {
			const filtered = tags.filter(option =>
				option.name.toLowerCase().includes(value.toLowerCase())
			);
			setFilteredOptions(filtered);
		} else {
			setFilteredOptions(tags);
		}
	};

  // const handleUpdateTag = (e) => {
  //   let found = false;
  //   for (let i = 0; i < dataTags.length(); i++) {
  //     if (dataTags[i] === inputValue)
  //       found = true;
  //   }

  //   if (found === false) {
  //     dataTags.push(inputValue);
  //   }
  //   //    data_tags.push(inputValue);
  //   // dispatch(updateTags(data_tags, token, data_id))
  // }

	const handleTagClick = (tag) => {
		if (!selectedTags.includes(tag)) {
			setSelectedTags([...selectedTags, tag]);
		}
		setInputValue("");
		// setFilteredOptions([]);
		setFilteredOptions(tags);
	};

  // const handleCreateTag = (e) => {
  //   if (!dataTags.includes(inputValue)) {
  //     const newTags = [...dataTags, inputValue];
  //     setDataTags(newTags);

  //     // filteredOptions.push(newTags);
  //     setFilteredOptions(...filteredOptions, newTags);

  //     dispatch(tagsUpdateSubmit(newTags, token));
  //   }
  //   // setFilteredOptions([]); // Clear suggestions
  // }
	const handleCreateTag = (e, is_new) => {
		if (inputValue && !selectedTags.includes(inputValue)) {
			const newTags = [...dataTags, inputValue];
			setDataTags(newTags);
			setSelectedTags([...selectedTags, inputValue]);
			if ( is_new == true ) {
				dispatch(createTag(inputValue, token));
				dispatch(getListTagsData(0, 49, token));
			}
//			dispatch(tagsUpdateSubmit(newTags, token)); // Update the backend with the new tags -> later
		}
		setInputValue("");
		// setFilteredOptions([]);
		setFilteredOptions(tags);
	};

	const handleRemoveTag = (tagToRemove) => {
		setSelectedTags(selectedTags.filter(tag => tag !== tagToRemove));
	};  

  /*
  useEffect(() => {
    if (data.success) {
      toast.success(data.success);
    }
    if (data.error.message) {
      toast.error(data.error.message);
    }
  }, [data.error.message, data.success]);
  */
  let height = 0 ;
  
  if (embedded == false ) {
	height = innerHeight * 0.756;
  }

  return (
    <>
		{!embedded &&<NavBar />}

		<Box width="90%" margin="0 auto" mt="5" position="relative" minH={height} >
        {/* Selected Tags Display */}
			<Box my={4} display="flex" flexWrap="wrap" gap={2}>
				{selectedTags.map((tag, index) => (
					<Tag
						key={index}
						size="md"
						borderRadius="full"
						variant="solid"
						colorScheme="blue"
					>
						<TagLabel>{tag}</TagLabel>
						<TagCloseButton onClick={() => handleRemoveTag(tag)} />
					</Tag>
				))}
			</Box>

			{/* Search Bar */}
			<Box display="flex" flexDirection="row" gap={2}>
				<Input
					placeholder="Search..."
					value={inputValue}
					onChange={handleInputChange}
					size="md"
					focusBorderColor="#A9A9A9"
				/>

				<Button
					bgColor={'blue'}
					color={'white'}
					_hover={{ bg: "blue.500", color: "white" }}
					p={4}
					borderRadius={'lg'}
					onClick={(e) => handleCreateTag(e, filteredOptions.length === 0)}
					// onClick={(e) => handleUpdateTag(e)}
					type='submit'
				>
					{/* Create Tag */}
					{filteredOptions.length === 0 ? "Create Tag" : "Add Tag"}
				</Button>
			</Box>

			{/* Suggestions List */}
			{filteredOptions.length > 0 && (
			<List
				position="relative"
				width="100%"
				bg="white"
				mt="2"
				borderRadius="md"
				border="1px solid #e2e8f0"
				maxHeight="150px"
				overflowY="auto"
				boxShadow="md"
				backgroundColor="#FFFF"
			>
				{filteredOptions.map((option, index) => (
					<ListItem
						key={index}
						padding="8px"
						_hover={{ backgroundColor: "#A9A9A9", cursor: "pointer" }}
						// onClick={() => {
						//   setInputValue(option); // Set the input value to the selected option
						//   setFilteredOptions([]); // Hide the suggestions after selecting
						// }}
						onClick={() => handleTagClick(option.name)}
					>
						{option.name}
					</ListItem>
				))}
			</List>
			)}
		</Box>

      {!embedded &&<Footer />}
    </>
  );
};


const Tags = ({embedded, listSelectedTags, listSetSelectedTags}) => {
	console.log("list", listSelectedTags);
	
	const [selectedTags, setSelectedTags] = useState([]);

	return (
		<>
			{!embedded && <SearchBarWithSuggestions 
				embedded={embedded} 
				selectedTags={selectedTags}
				setSelectedTags={setSelectedTags}
			/>}
			{embedded && <SearchBarWithSuggestions 
				embedded={embedded} 
				selectedTags={listSelectedTags}
				setSelectedTags={listSetSelectedTags}
			/>}
		</>
	);
}


export default Tags;