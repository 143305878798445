export const REQUEST_GET_LIST_TAGS = "REQUEST_GET_LIST_TAGS";
export const SUCCESS_GET_LIST_TAGS = "SUCCESS_GET_LIST_TAGS";
export const FAILURE_GET_LIST_TAGS = "FAILURE_GET_LIST_TAGS";

export const REQUEST_UPDATE_TAGS = "REQUEST_UPDATE_TAGS";
export const SUCCESS_UPDATE_TAGS = "SUCCESS_UPDATE_TAGS";
export const FAILURE_UPDATE_TAGS = "FAILURE_UPDATE_TAGS";

export const REQUEST_CREATE_TAG = "REQUEST_CREATE_TAG";
export const SUCCESS_CREATE_TAG = "SUCCESS_CREATE_TAG";
export const FAILURE_CREATE_TAG = "FAILURE_CREATE_TAG";