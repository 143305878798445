//const BASE_URL = "http://localhost:9090";
const BASE_URL = "https://bdconflict.watch";


// login
export const signup_url = `${BASE_URL}/api/v1/users/registration`;                    // ok
export const login_url = `${BASE_URL}/api/v1/users/login`;                            // ok
export const logout_url = `${BASE_URL}/api/v1/users/logout`;                          // ok
export const profileUpdate_url = `${BASE_URL}/api/v1/profile_edit`;


// home
export const data_url = `${BASE_URL}/api/v1/evidence/create`;                          // ok

export const add_links_data_url = `${BASE_URL}/api/v1/makepdf/create`;                  // ok

export const get_list_documents_url = (skip, limit, processed) => `${BASE_URL}/api/v1/makepdf/list/${skip}/${limit}/${processed}`;   // ok
export const file_download_url = `${BASE_URL}/api/v1/makepdf/download`;                 // ok
export const data_update_url = `${BASE_URL}/api/v1/makepdf/update`;                     // ok

export const archive_upload_url = `${BASE_URL}/api/v1/evidence/multicreate`;            // ok

export const get_list_archive_url = (skip, limit) => `${BASE_URL}/api/v1/evidence/list/${skip}/${limit}`;   // ok
export const file_download_archive_url = `${BASE_URL}/api/v1/makepdf/download`;         // ok
export const archive_update_url = `${BASE_URL}/api/v1/evidence/update`;                 // ok

export const get_user_list_url = `${BASE_URL}/api/v1/users/list`;                       // ok
export const user_update_url = `${BASE_URL}/api/v1/users/update`;                       //ok

export const get_tag_list_url = (skip, limit) => `${BASE_URL}/api/v1/tag/list/${skip}/${limit}`;   // ok
export const tag_create_url = `${BASE_URL}/api/v1/tag/create`;                 // ok
export const tag_update_url = `${BASE_URL}/api/v1/tag/update`;                 // ok
