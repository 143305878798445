import axios from "axios";
import {
    requestGetListDocuments,
    getListDocumentsSuccess,
    getListDocumentsFailure,
    requestUpdateDocument,
    updateDocumentSuccess,
    updateDocumentFailure,
    requestFileDownload,
    fileDownloadSuccess,
    fileDownloadFailure,
} from "../../ActionCreator/listDocuments";

import { data_update_url, file_download_url, get_list_documents_url } from "../../../allApiPath";


export const getListDocumentsData = (skip, limit, token, processed) => async (dispatch, getState) => {
    dispatch(requestGetListDocuments())

    axios.get(get_list_documents_url(skip, limit, processed), {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(getListDocumentsSuccess(response.data));
        }, error => {
            dispatch(getListDocumentsFailure(error));
        })
}


export const downloadFile = (filename, token) => async (dispatch) => {
    dispatch(requestFileDownload())

    try {
        const response = await axios.post(file_download_url, {
            filename: filename,
        }, {
            responseType: 'blob', // Important for file download
            headers: {
                'Authorization': token,
            }
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const fileName = filename.split('/').pop(); // Extract file name from the full path
        link.setAttribute('download', fileName); // Set the downloaded file's name

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        dispatch(fileDownloadSuccess("Download Success"));
    } catch (error) {
        console.error('Download error:', error);
        dispatch(fileDownloadFailure(error));
    }
};


export const dataUpdateSubmit = (
    id, 
    date, 
    selectedLocationOption, 
    url, 
    selectedCategoriesOptions, 
    selectedSourceTypeOption, 
    comment, 
    death, 
    injury, 
    propertyValue, 
    noOfHouses, 
    propertyVandalized, 
    selectedEventsOption, 
    selectedReviewStatusOption, 
    token,
    listSelectedTags,
) => async (dispatch, getState) => {
    dispatch(requestUpdateDocument())

    axios.post(data_update_url, {
        id: id,
        review_date: date,
        location: selectedLocationOption,
        source_url: url,
        categories: selectedCategoriesOptions,
        source_description: selectedSourceTypeOption,
        comment: comment,
        death: parseInt(death), 
        injury: parseInt(injury),
        property_value: propertyValue,
        number_of_houses: parseInt(noOfHouses),
        property_vandalized: parseInt(propertyVandalized),
        events: selectedEventsOption,
        review_status: selectedReviewStatusOption, 
        tags: listSelectedTags,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(updateDocumentSuccess(response.data));
        }, error => {
            dispatch(updateDocumentFailure(error))
        })
}