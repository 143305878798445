import axios from "axios";
import {
    requestSignup,
    signupSuccess,
    signupFailure,
    requestLogin,
    loginSuccess,
    loginFailure,
    // requestLogout,
    logoutSuccess,
    // logoutFailure,
    requestUpdateProfile,
    updateProfileSuccess,
    updateProfileFailure,

    requestUserList,
    getUserListSuccess,
    getUserListFailure,
    requestUserTypeChange,
    getUserTypeChangeSuccess,
    getUserTypeChangeFailure,

    // updateSessionExpiry,
} from "../../ActionCreator/login";
import { login_url, logout_url, profileUpdate_url, signup_url, get_user_list_url, user_update_url } from "../../../allApiPath";


// export const signup = (firstName, lastName, email, password) => async (dispatch, getState) => {
export const signup = (firstName, lastName, email, password, captchaValue) => async (dispatch, getState) => {
    dispatch(requestSignup())

    axios.post(signup_url, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        account_type: 'public',
        g_recaptcha_response: captchaValue,
    })
        .then((response) => {
            dispatch(signupSuccess(response.data));
            // dispatch(updateSessionExpiry(nextDate));
        }, error => {
            dispatch(signupFailure(error));
            // console.log(error);
        })
}

// export const login = (email, password, account_type) => async (dispatch, getState) => {
export const login = (email, password, account_type, captchaValue) => async (dispatch, getState) => {
    dispatch(requestLogin())

    axios.post(login_url, {
        email: email,
        password: password,
        //        account_type: account_type,
        g_recaptcha_response: captchaValue,
    })
        .then((response) => {
            dispatch(loginSuccess(response.data));
            // dispatch(updateSessionExpiry(nextDate));
        }, error => {
            dispatch(loginFailure(error))
        })
}


export const logout = (user_id, token) => async (dispatch, getState) => {
    dispatch(logoutSuccess());
    // dispatch(requestLogout())

    // axios.post(logout_url, {
    // user_id: user_id,
    // }, {
    // headers: {
    // 'Authorization': token,
    // },
    // })
    // .then((response) => {
    // dispatch(logoutSuccess(response.data));
    // dispatch(updateSessionExpiry(nextDate));
    // }, error => {
    // dispatch(logoutFailure(error))
    // })
}


export const profileUpdate = (userName, user_email, user_phone, address, birthday, token) => async (dispatch, getState) => {
    dispatch(requestUpdateProfile())

    axios.post(profileUpdate_url, {
        userName: userName,
        user_email: user_email,
        user_phone: user_phone,
        address: address,
        birthday: birthday,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(updateProfileSuccess(response.data));
        }, error => {
            dispatch(updateProfileFailure(error))
        })
}

export const getUserListData = (skip, limit, token) => async (dispatch, getState) => {
    dispatch(requestUserList())
    // console.log(token, skip, limit);

    axios.post(get_user_list_url, {
        skip: skip,
        limit: limit,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            // console.log(response.data);
            dispatch(getUserListSuccess(response.data));
            // dispatch(updateSessionExpiry(nextDate));
        }, error => {
            dispatch(getUserListFailure(error));
            // console.log(error);
        })
}


export const userTypeChange = (id, account_type, token) => async (dispatch, getState) => {
    dispatch(requestUserTypeChange())
    // console.log(token, skip, limit);

    axios.post(user_update_url, {
        id: id,
        account_type: account_type,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            // console.log(response.data);
            dispatch(getUserTypeChangeSuccess(response.data));
            // dispatch(updateSessionExpiry(nextDate));
        }, error => {
            dispatch(getUserTypeChangeFailure(error));
            // console.log(error);
        })
}

export const userBan = (id, token) => async (dispatch, getState) => {
    dispatch(requestUserTypeChange())
    // console.log(token, skip, limit);

    axios.post(user_update_url, {
        id: id,
        suspended: true,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            // console.log(response.data);
            dispatch(getUserTypeChangeSuccess(response.data));
            // dispatch(updateSessionExpiry(nextDate));
        }, error => {
            dispatch(getUserTypeChangeFailure(error));
            // console.log(error);
        })
}

