import axios from "axios";
import {
    requestSubmitArchiveData,
    submitArchiveDataSuccess,
    submitArchiveDataFailure,
    requestGetListArchive,
    getListArchiveSuccess,
    getListArchiveFailure,
    requestUpdateArchive,
    updateArchiveSuccess,
    updateArchiveFailure,
    requestFileDownloadArchive,
    fileDownloadArchiveSuccess,
    fileDownloadArchiveFailure,

    // updateSessionExpiry,
} from "../../ActionCreator/archive";

import { archive_update_url, archive_upload_url, file_download_archive_url, get_list_archive_url, } from "../../../allApiPath";


export const archiveDataSubmit = (formData, token) => async (dispatch, getState) => {
    dispatch(requestSubmitArchiveData())

    axios.post(archive_upload_url, formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': token,
            },
            // body: JSON.stringify(formData),
        }
    )
        .then((response) => {
            dispatch(submitArchiveDataSuccess(response.data));
        }, error => {
            dispatch(submitArchiveDataFailure(error))
        })
}


export const getListArchiveData = (skip, limit, token) => async (dispatch, getState) => {
    dispatch(requestGetListArchive())
    // console.log(token, skip, limit);

    axios.get(get_list_archive_url(skip, limit), {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            // console.log(response.data);
            dispatch(getListArchiveSuccess(response.data));
            // dispatch(updateSessionExpiry(nextDate));
        }, error => {
            dispatch(getListArchiveFailure(error));
            // console.log(error);
        })
}


export const downloadArchiveFile = (filename, token) => async (dispatch) => {
    dispatch(requestFileDownloadArchive())

    try {
        const response = await axios.post(file_download_archive_url, {
            filename: filename,
        }, {
            responseType: 'blob', // Important for file download
            headers: {
                // Authorization: `Bearer ${token}`,
                'Authorization': token,
            }
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const fileName = filename.split('/').pop(); // Extract file name from the full path
        link.setAttribute('download', fileName); // Set the downloaded file's name

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        dispatch(fileDownloadArchiveSuccess("Download Success"));
    } catch (error) {
        console.error('Download error:', error);
        dispatch(fileDownloadArchiveFailure(error));
    }
};


export const archiveUpdateSubmit = (
    id, 
    date, 
    selectedLocationOption, 
    url, 
    selectedCategoriesOptions, 
    selectedSourceTypeOption, 
    comment,
    death, 
    injury, 
    propertyValue, 
    noOfHouses, 
    propertyVandalized, 
    selectedEventsOption, 
    selectedReviewStatusOption, 
    token,
) => async (dispatch, getState) => {
    dispatch(requestUpdateArchive())

    axios.post(archive_update_url, {
        evidence_id: id,
        date: date,
        place: selectedLocationOption,
        source_url: url,
        categories: selectedCategoriesOptions,
        source_description: selectedSourceTypeOption,
        comment: comment,
        death: parseInt(death),
        injury: parseInt(injury),
        property_value: propertyValue,
        number_of_houses: parseInt(noOfHouses),
        property_vandalized: parseInt(propertyVandalized),
        events: selectedEventsOption,
        review_status: selectedReviewStatusOption, 
        categories: selectedCategoriesOptions,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(updateArchiveSuccess(response.data));
        }, error => {
            dispatch(updateArchiveFailure(error))
        })
}
