import { Box, Image } from "@chakra-ui/react";
import { useState, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;

function PDF({ src }) {
	const container = useRef(null);

	const [width, setWidth] = useState(0);

	useEffect(() => {
		setWidth(container.current.clientWidth);
	}, []);

	const [numPages, setNumPages] = useState(0);

	return (
		<>
			<div ref={container}>
				<Document
					file={src}
					onLoadSuccess={({ numPages }) => setNumPages(numPages)}
					onLoadError={console.error}
				>
					{Array.from(new Array(numPages)).map((_, i) => (
						<Page 
							pageNumber={i + 1} 
							width={width} 
							key={i} 
							renderTextLayer={false} 
							renderAnnotationLayer={false}
              scale={1}

						/>
					))}
				</Document>
			</div>
		</>
	);
}

const AttachmentPreview = ({ fileUrl, fileType }) => {
	
	const renderPreview = () => {
		if (fileType === "pdf") {
			return (
				<Box w="100%" p={0}>
					<PDF src={fileUrl} />
				</Box>
			)
		} else if (["jpg", "jpeg", "png", "gif"].includes(fileType)) {
			return (
				<Image 
					src={fileUrl} 
					alt="Image Preview" 
					objectFit="cover" 
					w="100%" 
					h="100%" 
				/>
			);
		} else if (fileType === "mp4") {
			return (
				<video controls style={{ width: "100%", height: "100%" }}>
					<source src={fileUrl} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			);
		} else {
			return <Box textAlign="center" p={4}>Unsupported file format.</Box>;
		}
	};

	return (
		<Box p={4} w="100%" mx="auto">
			{/* Attachment preview area */}
			<Box
				w={'100%'}
				backgroundColor="pink"
				border="1px solid #e2e8f0"
				borderRadius="md"
				overflow="hidden"
				bg="gray.100"
				display="flex"
				justifyContent="center"
				alignItems="center"
				mx="auto"
				position="relative"
			>
			{/* Render preview based on file type */}
			{renderPreview()}
			</Box>
		</Box>
	);
};

export default AttachmentPreview;