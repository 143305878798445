// GlobalStyles.jsx
import { Global } from "@emotion/react";

const GlobalStyles = () => (
  <Global
    styles={`
      @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali&display=swap');
      body {
        font-family: 'Noto Serif Bengali', sans-serif;
      }
    `}
  />
);

export default GlobalStyles;
