import { combineReducers } from "redux";
import {
    REQUEST_GET_LIST_TAGS,
    SUCCESS_GET_LIST_TAGS,
    FAILURE_GET_LIST_TAGS,
    REQUEST_UPDATE_TAGS,
    SUCCESS_UPDATE_TAGS,
    FAILURE_UPDATE_TAGS,
    SUCCESS_CREATE_TAG,
    FAILURE_CREATE_TAG,
    REQUEST_CREATE_TAG,

} from "../Constant/tags";

const initialState = {
    listTagsData: {
        listTags: [],
        error: "",
    },
    
    error: "",
    loading: false,
    // fileDownload: {
    //     success: '',
    //     error: "",
    // },
    tagsUpdate: {
        success: '',
        error: "",
    },
    tagsCreate: {
        success: '',
        error: "",
    },
    isFetching: false,
}

const listTagsData = (state = initialState.listTagsData, action) => {
    switch (action.type) {

        case REQUEST_GET_LIST_TAGS:
            return {
                ...state,
                listTags: [],
                error: '',
            }
        case SUCCESS_GET_LIST_TAGS:
            return {
                ...state,
                listTags: action.payload,
                error: '',
            }
        case FAILURE_GET_LIST_TAGS:
            return {
                ...state,
                listTags: [],
                error: action.payload,
            }

        default:
            return state;
    }
};

const updateTags = (state = initialState.tagsUpdate, action) => {
    switch (action.type) {

        case REQUEST_UPDATE_TAGS:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_UPDATE_TAGS:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_UPDATE_TAGS:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const createTags = (state = initialState.tagsUpdate, action) => {
    switch (action.type) {

        case REQUEST_CREATE_TAG:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_CREATE_TAG:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_CREATE_TAG:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const isFetching = (state = initialState.isFetching, action) => {
    switch (action.type) {
        case REQUEST_GET_LIST_TAGS:
        case REQUEST_UPDATE_TAGS:
            return true;

        case SUCCESS_GET_LIST_TAGS:
        case SUCCESS_UPDATE_TAGS:
        case FAILURE_GET_LIST_TAGS:
        case FAILURE_UPDATE_TAGS:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    listTagsData,
    updateTags,
    createTags,
    isFetching,
});

// export const selectListTags = state => state;
export const selectListTags = state => state.listTagsData && state.listTagsData.listTags || [];
export const selectUpdateTags = state => state.updateTags;

