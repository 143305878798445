import React, { useEffect, useState } from 'react';
import {
	Container, Stack, Text, Box, Button, Input, Select, FormControl, HStack, VStack, Flex,
	useDisclosure,
} from '@chakra-ui/react';

import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux';
import { selectToken, selectUpdateMessage, selectUserList } from '../Redux/Reducer';
import { getUserListData, userBan, userTypeChange } from '../Redux/Thunk/Login';


const ListItem = ({ handleStateChange, handleUserTypeChange, handleBan, td, currentPage, itemsPerPage, i }) => {

	const [selectedOption, setSelectedOption] = useState('');
	const [categoriesOptions, setCategoriesOptions] = useState([
		{ value: 'public', label: 'Public' },
		{ value: 'volunteer', label: 'Volunteer' },
		{ value: 'admin', label: 'Admin' },
	]);

	const handleChange = (state, id) => {
		handleStateChange(state, id);
		setSelectedOption(state);
	}


	return (
		<Flex
			mx="auto"
			direction={['column', 'column', 'row']}
			margin={3}
			padding={2}
			borderWidth={1}
			borderRadius="md"
			borderColor="black"
			key={td.id}
		>
			<Text w={["100%", "100%", "5%"]} fontSize={['xs', 'sm', 'md']} fontWeight="bold" color="black" textAlign="center" alignSelf="center">
				{i + 1 + (currentPage - 1) * itemsPerPage}
			</Text>

			<VStack alignSelf="center" alignItems="flex-start" gap={2} w={["100%", "100%", "70%"]}>
				<Text fontWeight="semibold" wordBreak="break-word" whiteSpace="normal">{td.first_name} {td.last_name}</Text>
			</VStack>

			<VStack alignSelf="center" alignItems="flex-start" gap={2} w={["100%", "100%", "70%"]}>
				<Text fontWeight="semibold" wordBreak="break-word" whiteSpace="normal">{td.email}</Text>
			</VStack>


			<Box w={["100%", "100%", "25%"]} p="1" alignContent={"center"}>
				{/*<Center>*/}
				<HStack
					direction={{ xs: 'column', sm: 'column', lg: 'row', md: 'row' }}
				//spacing={{base:4,md:2}}
				//justifyContent="center"
				//flexWrap="wrap"
				//	alignItems="center"
				>
					<Select
						placeholder={td.account_type}
						size={['md', 'md', 'lg']}
						variant="filled"
						focusBorderColor="blue.500"
						borderRadius="md"
						boxShadow="sm"
						onChange={(e) => handleChange(e.target.value, td.id)}
						value={selectedOption}
					>
						{categoriesOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Select>

					<Button
						bgColor="blue.500"
						color="white"
						_hover={{ bg: "blue.400", color: "white" }}
						p={6}
						// mb={3}
						ml={1}
						onClick={() => handleUserTypeChange()}
					>
						Approve
					</Button>

					<Button
						bgColor="white"
						color="blue.500"
						_hover={{ bg: "blue.300", color: "white" }}
						py={6}
						// mb={3}
						ml={1}
						onClick={() => handleBan(td.id)}
					>
						Ban
					</Button>
					{/*</Center>*/}
				</HStack>
			</Box>
		</Flex>
	)
}

const UserList = () => {
	const { innerHeight } = window;

	const dispatch = useDispatch();
	const token = useSelector(state => selectToken(state));
	const update = useSelector(state => selectUpdateMessage(state));
	const filteredData = useSelector(state => selectUserList(state));

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);  // Default to 5 items per page

	const handlePageChange = (page) => {
		// if (page >= 1 && page <= totalPages) {
		if (page >= 1) {
			setCurrentPage(page);
		}
	};


	//fetch user_list and store it to redux state
	useEffect(() => {
		dispatch(getUserListData((currentPage - 1) * itemsPerPage, itemsPerPage, token));
	}, [currentPage, dispatch, itemsPerPage, token]);
	// console.log("filter", filteredData);

	// const [tableData, setTableData] = useState([]);

	// // data set 
	// useEffect(() => {
	// 	if (data && data.listArchive) {
	// 		setTableData(data.listArchive);
	// 	}
	// }, [data]);
	// // console.log(selectedCategoriesOptions);


	const [id, setId] = useState('');
	const [account_type, setAccountType] = useState("public");

	//	let selected_user_role = [];	
	const handleStateChange = (state, id) => {
		// console.log(state);
		// console.log(id);
		setId(id);
		setAccountType(state);
	};

	// change user's account privilege
	const handleUserTypeChange = () => {
		if (id !== '') {
			dispatch(userTypeChange(id, account_type, token));
		}
	};

	// for ban
	const [banFlag, setBanFlag] = useState(false);
	const handleEdit = (id) => {
		setBanFlag(true);
		dispatch(userBan(id, token));
	};

	// toast 
	useEffect(() => {
		if (update.success) {
			// if (update.success === "user updated") {
			// toast.success(update.success);
			if (!banFlag) {
				toast.success("User access has been updated");
			}
			else {
				toast.success("User has been banned");
				setBanFlag(false);
			}
		}
		/*
			if (update.error?.message) {
				toast.error(update.error.message);
			}
		*/
	}, [update, banFlag]);


	return (
		<div sx={{ maxWidth: '100%', overflowX: 'hidden', }}>
			<Navbar />

			<Container
				pb={{ base: '2', md: '4' }}
				px={{ base: '3', md: '8' }}
				maxWidth="1400px"
				mx="auto"
				minH={innerHeight * 0.756}
			>
				<Box p={[2, 6]} boxShadow="dark-lg" rounded="md" w="100%">
					<Text fontSize="lg" fontWeight="semibold" color="black" textAlign="center" my={1} p={1}>
						List of Users
					</Text>

					{/* Document list */}
					{/* {paginatedData.length > 0 ? ( */}
					{filteredData && filteredData.listUser && filteredData.listUser.length > 0 ? (
						// paginatedData.map((td, i) => (
						filteredData.listUser.map((td, i) => (
							<ListItem
								td={td}
								i={i}
								handleUserTypeChange={handleUserTypeChange}
								handleStateChange={handleStateChange}
								handleBan={handleEdit}
								key={td.id}
								currentPage={currentPage}
								itemsPerPage={itemsPerPage}
							/>
						))
					) : (
						<Text textAlign="center" my={4}>
							No data found for the current search/filter.
						</Text>
					)}

					{/* Pagination controls */}
					<Stack gap={[2, 5]} marginY={3} mt={4}
						direction={['column', 'row']}
						justify={"space-between"}
					>
						<FormControl width={["100%", "40%", "30%", "20%"]}>
							<Select
								placeholder="Select items per page"
								size="md"
								variant="filled"
								onChange={(e) => {
									setItemsPerPage(Number(e.target.value));
									setCurrentPage(1);  // Reset to the first page when items per page changes
								}}
							>
								<option value={10}>10</option>
								<option value={20}>20</option>
								<option value={50}>50</option>
								<option value={100}>100</option>
							</Select>
						</FormControl>

						<HStack justify="center" width={["100%", "60%", "40%", "30%"]}>
							<Button
								onClick={() => handlePageChange(currentPage - 1)}
								isDisabled={currentPage === 1}
							>
								Previous
							</Button>

							<Text>
								{/* Page {currentPage} of {totalPages} */}
								Page {currentPage}
							</Text>

							<Button
								onClick={() => handlePageChange(currentPage + 1)}
							// isDisabled={currentPage === totalPages}
							>
								Next
							</Button>
						</HStack>
					</Stack>
				</Box>
			</Container >
			<Footer />
		</div>
	);
};

export default UserList;
