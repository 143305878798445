export const REQUEST_SIGNUP = "REQUEST_SIGNUP";
export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const REQUEST_LOGOUT = "REQUEST_LOGOUT";
// export const REQUEST_FETCH_ID = "REQUEST_FETCH_ID";
// export const REQUEST_FETCH_PROFILE = "REQUEST_FETCH_PROFILE";
export const REQUEST_UPDATE_PROFILE = "REQUEST_UPDATE_PROFILE";

export const SUCCESS_SIGNUP = "SUCCESS_SIGNUP";
export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const SUCCESS_LOGOUT = "SUCCESS_LOGOUT";
// export const SUCCESS_FETCH_ID = "SUCCESS_FETCH_ID";
// export const SUCCESS_FETCH_PROFILE = "SUCCESS_FETCH_PROFILE" 
export const SUCCESS_UPDATE_PROFILE = "SUCCESS_UPDATE_PROFILE"

export const FAILURE_SIGNUP = "FAILURE_SIGNUP";
export const FAILURE_LOGIN = "FAILURE_LOGIN";
export const FAILURE_LOGOUT = "FAILURE_LOGOUT";
// export const FAILURE_FETCH_PROFILE = "FAILURE_FETCH_PROFILE"; 
// export const FAILURE_FETCH_ID = "FAILURE_FETCH_ID";
export const FAILURE_UPDATE_PROFILE = "FAILURE_UPDATE_PROFILE";


export const REQUEST_USER_LIST = "REQUEST_USER_LIST";
export const SUCCESS_UPDATE_USER_LIST = "SUCCESS_UPDATE_USER_LIST";
export const FAILURE_UPDATE_USER_LIST = "FAILURE_UPDATE_USER_LIST";

export const REQUEST_USER_TYPE_CHANGE = "REQUEST_USER_TYPE_CHANGE";
export const SUCCESS_UPDATE_USER_TYPE = "SUCCESS_UPDATE_USER_TYPE";
export const FAILURE_UPDATE_USER_TYPE = "FAILURE_UPDATE_USER_TYPE";


// export const UPDATE_SESSION_EXPIRY = "UPDATE_SESSION_EXPIRY";