import {
    REQUEST_GET_LIST_DOCUMENTS,
    SUCCESS_GET_LIST_DOCUMENTS,
    FAILURE_GET_LIST_DOCUMENTS,
    REQUEST_UPDATE_DOCUMENT,
    SUCCESS_UPDATE_DOCUMENT,
    FAILURE_UPDATE_DOCUMENT,
    REQUEST_FILE_DOWNLOAD,
    SUCCESS_FILE_DOWNLOAD,
    FAILURE_FILE_DOWNLOAD,

    // UPDATE_SESSION_EXPIRY,
} from "../Constant/listDocuments";


export const requestGetListDocuments = () => ({
    type: REQUEST_GET_LIST_DOCUMENTS,
})
export const getListDocumentsSuccess = (data) => ({
    type: SUCCESS_GET_LIST_DOCUMENTS,
    payload: data,
})
export const getListDocumentsFailure = (error) => ({
    type: FAILURE_GET_LIST_DOCUMENTS,
    payload: error,
})

export const requestUpdateDocument = () => ({
    type: REQUEST_UPDATE_DOCUMENT,
})
export const updateDocumentSuccess = (data) => ({
    type: SUCCESS_UPDATE_DOCUMENT,
    payload: data,
})
export const updateDocumentFailure = (error) => ({
    type: FAILURE_UPDATE_DOCUMENT,
    payload: error,
})

export const requestFileDownload = () => ({
    type: REQUEST_FILE_DOWNLOAD,
})
export const fileDownloadSuccess = (data) => ({
    type: SUCCESS_FILE_DOWNLOAD,
    payload: data,
})
export const fileDownloadFailure = (error) => ({
    type: FAILURE_FILE_DOWNLOAD,
    payload: error,
})

// export const updateSessionExpiry = (nextDay) => ({
//     type: UPDATE_SESSION_EXPIRY,
//     payload: nextDay,
// })
