import React, { useEffect, useState } from 'react';
import {
	Container, Stack, Text, Box, Button, Input, Select, FormControl, HStack, VStack, Flex,
	Center, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure,  useBreakpointValue, FormLabel, Textarea, Checkbox
} from '@chakra-ui/react';
import { Form } from 'react-router-dom';
import { FaRegEdit } from "react-icons/fa";

import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import FilePreview from "../Components/FilePreview";
import TagField from "./Tag";

import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux';
import { selectDownloadFile, selectListDocuments, selectToken, selectUpdateData } from '../Redux/Reducer';
import { dataUpdateSubmit, downloadFile, getListDocumentsData } from '../Redux/Thunk/ListDocuments';

const ListDocuments = () => {
	const { innerHeight } = window;

	// Decode the URL to get the readable Bangla text
	const decode = (url) => {
		const decodedUrl = decodeURIComponent(url);
		return decodedUrl;
	}

	const dispatch = useDispatch();

	const token = useSelector(state => selectToken(state));
	const data = useSelector(state => selectListDocuments(state));
	const update = useSelector(state => selectUpdateData(state));
	const downloadMessage = useSelector(state => selectDownloadFile(state));

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(5);  // Default to 5 items per page
	const [curFilename, setFileName] = useState("");
	const [processed, setProcessed] = useState("unprocessed");

	const [curFileType, setFileType] = useState("");
	
	// initial data load and after recall
	useEffect(() => {
		dispatch(getListDocumentsData((currentPage - 1) * itemsPerPage, itemsPerPage, token, processed));
	}, [dispatch, token, currentPage, itemsPerPage, processed]);

	const [tableData, setTableData] = useState([]);

	// data set 
	useEffect(() => {
		if (data && data.listDocuments) {
			setTableData(data.listDocuments);
		}
	}, [data]);


	// Modal for editing
	const [id, setId] = useState('');

	const [date, setDate] = useState('');
	const handleDateChange = (e) => setDate(e.target.value);

	const [selectedLocationOption, setSelectedLocationOption] = useState('');
	const [optionsLocation, setOptionsLocation] = useState([
		{ value: 'ঢাকা', label: 'ঢাকা' },
		{ value: 'ফরিদপুর', label: 'ফরিদপুর' },
		{ value: 'গাজীপুর', label: 'গাজীপুর' },
		{ value: 'গোপালগঞ্জ', label: 'গোপালগঞ্জ' },
		{ value: 'কিশোরগঞ্জ', label: 'কিশোরগঞ্জ' },
		{ value: 'মাদারীপুর', label: 'মাদারীপুর' },
		{ value: 'মানিকগঞ্জ', label: 'মানিকগঞ্জ' },
		{ value: 'মুন্সীগঞ্জ', label: 'মুন্সীগঞ্জ' },
		{ value: 'নারায়ণগঞ্জ', label: 'নারায়ণগঞ্জ' },
		{ value: 'নরসিংদী', label: 'নরসিংদী' },
		{ value: 'রাজবাড়ী', label: 'রাজবাড়ী' },
		{ value: 'শরীয়তপুর', label: 'শরীয়তপুর' },
		{ value: 'টাঙ্গাইল', label: 'টাঙ্গাইল' },
		{ value: 'জামালপুর', label: 'জামালপুর' },
		{ value: 'শেরপুর', label: 'শেরপুর' },
		{ value: 'ময়মনসিংহ', label: 'ময়মনসিংহ' },
		{ value: 'নেত্রকোনা', label: 'নেত্রকোনা' },
		{ value: 'কুড়িগ্রাম', label: 'কুড়িগ্রাম' },
		{ value: 'লালমনিরহাট', label: 'লালমনিরহাট' },
		{ value: 'নীলফামারী', label: 'নীলফামারী' },
		{ value: 'রংপুর', label: 'রংপুর' },
		{ value: 'গাইবান্ধা', label: 'গাইবান্ধা' },
		{ value: 'ঠাকুরগাঁও', label: 'ঠাকুরগাঁও' },
		{ value: 'দিনাজপুর', label: 'দিনাজপুর' },
		{ value: 'পঞ্চগড়', label: 'পঞ্চগড়' },
		{ value: 'বগুড়া', label: 'বগুড়া' },
		{ value: 'নওগাঁ', label: 'নওগাঁ' },
		{ value: 'নাটোর', label: 'নাটোর' },
		{ value: 'জয়পুরহাট', label: 'জয়পুরহাট' },
		{ value: 'চাঁপাইনবাবগঞ্জ', label: 'চাঁপাইনবাবগঞ্জ' },
		{ value: 'রাজশাহী', label: 'রাজশাহী' },
		{ value: 'পাবনা', label: 'পাবনা' },
		{ value: 'সিরাজগঞ্জ', label: 'সিরাজগঞ্জ' },
		{ value: 'যশোর', label: 'যশোর' },
		{ value: 'সাতক্ষীরা', label: 'সাতক্ষীরা' },
		{ value: 'মেহেরপুর', label: 'মেহেরপুর' },
		{ value: 'নড়াইল', label: 'নড়াইল' },
		{ value: 'চুয়াডাঙ্গা', label: 'চুয়াডাঙ্গা' },
		{ value: 'কুষ্টিয়া', label: 'কুষ্টিয়া' },
		{ value: 'মাগুরা', label: 'মাগুরা' },
		{ value: 'খুলনা', label: 'খুলনা' },
		{ value: 'বাগেরহাট', label: 'বাগেরহাট' },
		{ value: 'ঝিনাইদহ', label: 'ঝিনাইদহ' },
		{ value: 'বরিশাল', label: 'বরিশাল' },
		{ value: 'পটুয়াখালী', label: 'পটুয়াখালী' },
		{ value: 'পিরোজপুর', label: 'পিরোজপুর' },
		{ value: 'ভোলা', label: 'ভোলা' },
		{ value: 'ঝালকাঠি', label: 'ঝালকাঠি' },
		{ value: 'বরগুনা', label: 'বরগুনা' },
		{ value: 'সিলেট', label: 'সিলেট' },
		{ value: 'মৌলভীবাজার', label: 'মৌলভীবাজার' },
		{ value: 'হবিগঞ্জ', label: 'হবিগঞ্জ' },
		{ value: 'সুনামগঞ্জ', label: 'সুনামগঞ্জ' },
		{ value: 'চট্টগ্রাম', label: 'চট্টগ্রাম' },
		{ value: 'কক্সবাজার', label: 'কক্সবাজার' },
		{ value: 'ব্রাহ্মণবাড়িয়া', label: 'ব্রাহ্মণবাড়িয়া' },
		{ value: 'বান্দরবান', label: 'বান্দরবান' },
		{ value: 'রাঙ্গামাটি', label: 'রাঙ্গামাটি' },
		{ value: 'নোয়াখালী', label: 'নোয়াখালী' },
		{ value: 'চাঁদপুর', label: 'চাঁদপুর' },
		{ value: 'লক্ষ্মীপুর', label: 'লক্ষ্মীপুর' },
		{ value: 'ফেনী', label: 'ফেনী' },
		{ value: 'খাগড়াছড়ি', label: 'খাগড়াছড়ি' },
		{ value: 'কুমিল্লা', label: 'কুমিল্লা' },
		{ value: 'নরসিংদী', label: 'নরসিংদী' }
	]);

	const handleLocationChange = (event) => {
		setSelectedLocationOption(event.target.value);
	};

	const [url, setURL] = useState('');
	const handleURLChange = (e) => setURL(decode(e.target.value));

	const [selectedCategoriesOptions, setSelectedCategoriesOptions] = useState([]);
	const [categoriesOptions, setCategoriesOptions] = useState([
		{ value: 'হিন্দু নির্যাতন', label: 'হিন্দু নির্যাতন' },
		{ value: 'ঘরবাড়ি ও কারখানা লুটপাট(আইন শৃঙ্খলা পরিস্থিতি অবনতি)', label: 'ঘরবাড়ি ও কারখানা লুটপাট(আইন শৃঙ্খলা পরিস্থিতি অবনতি)' },
		{ value: 'সাংবাদিক নির্যাতন', label: 'সাংবাদিক নির্যাতন' },
		{ value: 'শিক্ষক নির্যাতন', label: 'শিক্ষক নির্যাতন' },
		{ value: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনী কর্তৃক অত্যাচার', label: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনী কর্তৃক অত্যাচার' },
		{ value: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনীর উপরে ঘটে যাওয়া নৃশংসতা', label: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনীর উপরে ঘটে যাওয়া নৃশংসতা' },
		{ value: 'উসকানীমূলক বক্তব্য', label: 'উসকানীমূলক বক্তব্য' },
		{ value: 'প্রকাশ্য জঙ্গীবাদী তৎপরতা', label: 'প্রকাশ্য জঙ্গীবাদী তৎপরতা' },
		{ value: 'প্রকাশ্য সাম্প্রদায়িক তৎপরতা', label: 'প্রকাশ্য সাম্প্রদায়িক তৎপরতা' },
		{ value: 'অগনতান্ত্রিক আচরন', label: 'অগনতান্ত্রিক আচরন' },
		{ value: 'বিচার বিভাগীয় ব্যর্থতা', label: 'বিচার বিভাগীয় ব্যর্থতা' },
		{ value: '১৯৭১ সাল  সম্পর্কিত যেকোন ‌অপপ্রচার অথবা হামলা', label: '১৯৭১ সাল  সম্পর্কিত যেকোন ‌অপপ্রচার অথবা হামলা' },
		{ value: 'সাংস্কৃতিক আইকন, বা মূর্তি সম্পর্কিত যেকোন হামলা', label: 'সাংস্কৃতিক আইকন, বা মূর্তি সম্পর্কিত যেকোন হামলা' },
		{ value: 'Other', label: 'Other' },
	]);

	const handleCheckboxChange = (value) => {
		setSelectedCategoriesOptions((prev) =>
			prev.includes(value)
				? prev.filter((item) => item !== value)
				: [...prev, value]
		);
	};

	const [selectedSourceTypeOption, setSelectedSourceTypeOption] = useState('');
	const [options, setOptions] = useState([
		{ value: 'Newspaper', label: 'Newspaper' },
	]);
	const handleSourceTypeChange = (event) => {
		setSelectedSourceTypeOption(event.target.value);
	};

	const [comment, setComment] = useState('');
	const handleCommentChange = (e) => setComment(e.target.value);

	const [death, setDeath] = useState('');
	const handleDeathChange = (e) => setDeath(e.target.value);

	const [injury, setInjury] = useState('');
	const handleInjuryChange = (e) => setInjury(e.target.value);

	const [propertyValue, setPropertyValue] = useState('');
	const handlePropertyValueChange = (e) => setPropertyValue(e.target.value);

	const [noOfHouses, setNoOfHouses] = useState('');
	const handleNoOfHousesChange = (e) => setNoOfHouses(e.target.value);

	const [listSelectedTags, listSetSelectedTags] = useState([]);

	const [propertyVandalized, setPropertyVandalized] = useState('');
	const handlePropertyVandalizedChange = (e) => setPropertyVandalized(e.target.value);

	const [selectedEventsOption, setSelectedEventsOption] = useState('');
	const [optionsEvents, setOptionsEvents] = useState([
		{ value: 'event1', label: 'Event1' },
		{ value: 'event2', label: 'Event2' },
		{ value: 'event3', label: 'Event3' },
		{ value: 'event4', label: 'Event4' },
	]);
	const handleEventsChange = (event) => {
		setSelectedEventsOption(event.target.value);
	};

	const [selectedReviewStatusOption, setSelectedReviewStatusOption] = useState('');
	const [optionsReviewStatus, setOptionsReviewStatus] = useState([
		{ value: 'clear', label: 'Clear' },
		{ value: 'duplicate', label: 'Duplicate' },
		{ value: 'need_more_documents', label: 'Need More Documents' },
		{ value: 'garbage', label: 'Garbage' },
	]);
	const handleReviewStatusChange = (event) => {
		setSelectedReviewStatusOption(event.target.value);
	};

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isFileViewOpen, setFileViewOpen] = useState(false);
	
	const onFileViewClose = () => {
		setFileViewOpen(false);
	}
	
	const handleEdit = (doc) => {
		setId('');
		setDate('');
		setSelectedLocationOption('');
		setComment('');
		setURL('');
		setSelectedCategoriesOptions([]);
		setSelectedSourceTypeOption('');
		setDeath('');
		setInjury('');
		setPropertyValue('');
		setNoOfHouses('');
		setPropertyVandalized('');
		setSelectedEventsOption('');
		setSelectedReviewStatusOption("");

		setId(doc.id);
		setDate(doc.date);
		setSelectedLocationOption(doc.location);
		setComment(doc.comment);
		setURL(decode(doc?.source_url));
		setSelectedCategoriesOptions(doc.categories);
		setSelectedSourceTypeOption(doc.description);
		setDeath(doc.death);
		setInjury(doc.injury);
		setPropertyValue(doc.property_value);
		setNoOfHouses(doc.number_of_houses);
		setPropertyVandalized(doc.property_vandalized);
		setSelectedEventsOption(doc.event);
		setSelectedReviewStatusOption(doc.review_status);
		listSetSelectedTags(doc.tags || []);
		// setEditData(doc);  // Set the selected document data
		onOpen();  // Open the modal
	};
	// data update
	const handleSubmit = event => {
		event.preventDefault();
		console.log("before submitting");
		console.log(listSelectedTags);
		dispatch(dataUpdateSubmit(
			id, 
			date, 
			selectedLocationOption, 
			url, 
			selectedCategoriesOptions, 
			selectedSourceTypeOption, 
			comment, 
			death, 
			injury, 
			propertyValue, 
			noOfHouses, 
			propertyVandalized, 
			selectedEventsOption, 
			selectedReviewStatusOption, 
			token,
			listSelectedTags
		));

		onClose();
	};
	// toast 
	useEffect(() => {
		if (update.success) {
			toast.success(update.success);
			dispatch(getListDocumentsData((currentPage - 1) * itemsPerPage, itemsPerPage, token, processed));
		}
		if (update.error.message) {
			toast.error(update.error.message);
		}
	}, [update.error.message, update.success, dispatch, onClose, currentPage, itemsPerPage, token, processed]);


	// DOWNLOAD single file
	const [downStatus, setDownStatus] = useState('');

	const handlePreview = (file) => {
		if (file.filename) {
			setFileName(file.filename);
			if( file.source_type == "webpage") {
				setFileType("pdf");
			}
			if( file.source_type == "facebook") {
				setFileType("mp4");
			}
			setFileViewOpen(true);
			console.log("inside handle preview")
		}
	};

	const handleDownload = (filename) => {
		if (filename) {
			setDownStatus(filename);
			toast.success("Your download request has been received and will be delivered soon.");
			dispatch(downloadFile(filename, token));
		}
	};
	// download all file from list
	const handleAllDownload = () => {
		toast.success("Your download request of multiple file has been received and will be delivered soon.");
		filteredData.map((td, i) => (
			dispatch(downloadFile(td.filename, token))
		))
	};
	// download all file from select
	const [selectFlag, setSelectFlag] = useState(false);
	const [downloadButtonText, setDownloadButtonText] = useState('Download All Listed File');
	const [selectButtonText, setSelectButtonText] = useState('Select Items');
	const handleSelectClick = (e) => {
		setSelectFlag(!e);
		setDownloadButtonText(e ? 'Download All Listed File' : 'Download All Selected File')
		setSelectButtonText(e ? 'Select Items' : 'Unselect')
		if (e) {
			setSelectFile([]);
		}
	}
	const handleSelectAll = () => {
		setSelectFile([]);
		filteredData.map((td, i) => (
			setSelectFile((prev) => [...prev, td.filename])
		));
	}
	const [selectedFile, setSelectFile] = useState([]);
	const handleSelectFileDownload = () => {
		if (selectedFile.length > 0) {
			toast.success("Your download request of multiple file has been received and will be delivered soon.");
			selectedFile.map((filename, i) => (
				dispatch(downloadFile(filename, token))
			))
		}
		else {
			toast.error("Please, Select at least One file to download");
		}
	};
	// toast 
	useEffect(() => {
		if (downloadMessage.success) {
			setDownStatus('');
			// 	toast.success(downloadMessage.success);
			// 	// toast.success("This link has been documented");
			// 	// console.log(downloadMessage);
		}
		if (downloadMessage.error.message) {
			setDownStatus('');
			toast.error(downloadMessage.error.message);
			// console.log(downloadMessage);
		}
	}, [downloadMessage.error.message, downloadMessage.success]);


	// for search
	const [selectedSearchOption, setSelectedSearchOption] = useState('');
	const [selectedSearch, setSelectedSearch] = useState('');

	const filteredData = tableData.filter((item) => {
		const matchesCategory = selectedSearchOption
			? item.categories.some(category => category.toLowerCase() === selectedSearchOption.toLowerCase())
			: true;

		const matchesSearch = selectedSearch
			// ? item.categories.some(category => category.toLowerCase().includes(selectedSearch.toLowerCase())) ||
			// item.description.toLowerCase().includes(selectedSearch.toLowerCase())
			// : true;
			? item.description.toLowerCase().includes(selectedSearch.toLowerCase())
			: true;

		return matchesCategory && matchesSearch;
	});

	// Calculate total pages based on total data length
	// Make sure `totalCount` is available and valid before calculating totalPages
	// const totalPages = data && data.totalCount ? Math.ceil(data.totalCount / itemsPerPage) : 1;
	// const totalPages = Math.ceil(filteredData.length / itemsPerPage);

	const handlePageChange = (page) => {
		// if (page >= 1 && page <= totalPages) {
		if (page >= 1) {
			setCurrentPage(page);
		}
	};

	const handleSearch = (e) => {
		e.preventDefault();
		setCurrentPage(1);
	};
	console.log("list select tags")
	console.log(listSelectedTags);
	
	return (
		<>
			<Navbar />

			<Container
				pb={{ base: '2', md: '4' }}
				px={{ base: '3', md: '8' }}
				maxWidth="1400px"
				mx="auto"
				minH={innerHeight * 0.756}
			>
				<Box p={[2, 6]} boxShadow="dark-lg" rounded="md" w="100%">
					<Text fontSize="lg" fontWeight="semibold" color="black" textAlign="center" my={1} p={1}>
						All Documents Info
					</Text>

					{/* Search section */}
					<Box p="4" mb={6} boxShadow="md" maxWidth="500px" mx="auto">
						<Form onSubmit={handleSearch}>
							<FormControl isRequired>
								<Stack gap={[2, 5]} marginY={3} direction={['column', 'row']}>
									<Select
										placeholder="Select Category"
										size={['md', 'md', 'lg']}
										variant="filled"
										focusBorderColor="blue.500"
										borderRadius="md"
										boxShadow="sm"
										onChange={(e) => setSelectedSearchOption(e.target.value)}
										value={selectedSearchOption}
									>
										{categoriesOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</Select>
								</Stack>

								<HStack>
									<Input
										type="text"
										placeholder="Search on Source Type Description"
										px={2}
										onChange={(e) => setSelectedSearch(e.target.value)}
										value={selectedSearch}
									/>

									<Button
										bgColor={selectedSearch || selectedSearchOption ? 'blue' : 'gray'}
										px={5}
										borderRadius="lg"
										color="white"
										isDisabled={!selectedSearch && !selectedSearchOption}
										type="submit"
									>
										Search
									</Button>
								</HStack>
							</FormControl>
						</Form>
					</Box>

					<Box
						px={["2", "4"]}
						display="flex"
						flexDirection="column"
						alignItems={["center", "center"]}
					>
						<HStack columnGap={1}>
							<Button
								px={[1, 5]}
								isDisabled={(filteredData.length > 0) ? false : true}
								borderRadius="lg"
								bgColor="blue.500"
								color="white"
								_hover={{ bg: "blue.400", color: "white" }}
								fontSize={["xs", "sm", "md"]}
								onClick={() => {
									setProcessed("processed");
								}}
							>
								Verified
							</Button>

							<Button
								px={[1, 5]}
								isDisabled={(filteredData.length > 0) ? false : true}
								borderRadius="lg"
								bgColor="blue.500"
								color="white"
								_hover={{ bg: "blue.400", color: "white" }}
								fontSize={["xs", "sm", "md"]}
								onClick={() => {
									setProcessed("inprogress");
								}}
							>
								Further Review
							</Button>

							<Button
								px={[1, 5]}
								isDisabled={(filteredData.length > 0) ? false : true}
								borderRadius="lg"
								bgColor="blue.500"
								color="white"
								_hover={{ bg: "blue.400", color: "white" }}
								fontSize={["xs", "sm", "md"]}
								onClick={() => setProcessed("unprocessed")}
							>
								New to the Queue
							</Button>
							
						</HStack>
					</Box>

					{/* all file download in one click */}
					<Box
						px={["2", "4"]}
						display="flex"
						flexDirection="column"
						alignItems={["center", "flex-end"]}
					>
						<HStack columnGap={1}>
							<Button
								px={[1, 5]}
								isDisabled={(filteredData.length > 0) ? false : true}
								borderRadius="lg"
								// type="submit"
								bgColor="blue.500"
								color="white"
								_hover={{ bg: "blue.400", color: "white" }}
								// mb={3}
								fontSize={["xs", "sm", "md"]}
								onClick={() => {
									if (!selectFlag) handleAllDownload();
									else handleSelectFileDownload();
								}}
							>
								{downloadButtonText}
							</Button>

							{selectFlag &&
								<Button
									px={[1, 5]}
									isDisabled={(filteredData.length > 0) ? false : true}
									borderRadius="lg"
									bgColor="blue.500"
									color="white"
									_hover={{ bg: "blue.400", color: "white" }}
									fontSize={["xs", "sm", "md"]}
									onClick={() => handleSelectAll()}
								>
									Select All
								</Button>
							}

							<Button
								px={[1, 5]}
								isDisabled={(filteredData.length > 0) ? false : true}
								borderRadius="lg"
								// type="submit"
								bgColor="blue.500"
								color="white"
								_hover={{ bg: "blue.400", color: "white" }}
								// mb={3}

								//fontSize={{base: "sm", md: "lg"}}
								fontSize={["xs", "sm", "md"]}
								//size={{base: "sm", md: "lg"}}
								onClick={() => handleSelectClick(selectFlag)}
							>
								{selectButtonText}
							</Button>

						</HStack>
					</Box>

					{/* Document list */}
					{/* {paginatedData.length > 0 ? ( */}
					{filteredData.length > 0 ? (
						// paginatedData.map((td, i) => (
						filteredData.map((td, i) => (
							<Flex
								mx="auto"
								direction={['column', 'column', 'row']}
								margin={3}
								padding={2}
								borderWidth={1}
								borderRadius="md"
								borderColor="black"
								key={td.id}
							>
								<Text w={["100%", "100%", "5%"]} fontSize={['xs', 'sm', 'md']} fontWeight="bold" color="black" textAlign="center" alignSelf="center">
									{i + 1 + (currentPage - 1) * itemsPerPage}
								</Text>

								<VStack align="flex-start" gap={2} w={["100%", "100%", "70%"]}>
									{/* <Text fontWeight="semibold" wordBreak="break-word" whiteSpace="normal">id: {td.id}</Text> */}
									<Text fontWeight="semibold" wordBreak="break-word" whiteSpace="normal">Category: {td.categories.join(', ')}</Text>
									<Text fontWeight="semibold" wordBreak="break-word" whiteSpace="normal">Source Type Description: {td.description}</Text>
									<Text fontWeight="semibold" wordBreak="break-word" whiteSpace="normal"
									>
										Source URL:{" "}
										<Link
											fontWeight="semibold"
											wordBreak="break-word"
											whiteSpace="normal"
											href={td.source_url}
											isExternal
										>
											{decode(td.source_url)} {/* Display the decoded URL */}
										</Link>
									</Text>
								</VStack>

								<Box w={["100%", "100%", "25%"]} p="1" alignContent={"center"}>
									<Center>
										<Button
											isDisabled={"waiting" === td.status ? true : false}
											bgColor="blue.500"
											color="white"
											_hover={{ bg: "blue.400", color: "white" }}
											mb={3}
											onClick={() => handleDownload(td.filename)}
											fontSize={{ base: "sm", md: "lg" }}
											size={{ base: "sm", md: "lg" }}
										>
											Download
										</Button>
										<Button
											//  as="a"
											//  href={td.file}
											//  download // This attribute tells the browser to download the file
											isDisabled={"waiting" === td.status ? true : false}
											bgColor="blue.500"
											color="white"
											_hover={{ bg: "blue.400", color: "white" }}
											mb={3}
											onClick={() => handlePreview(td)}
											fontSize={{ base: "sm", md: "lg" }}
											size={{ base: "sm", md: "lg" }}
										>
											Preview
										</Button>

										<Button
											bgColor="white"
											color="blue.500"
											_hover={{ bg: "blue.300", color: "white" }}
											mb={3}
											ml={1}
											onClick={() => handleEdit(td)}
										>
											<FaRegEdit size="25" />
										</Button>

										{selectFlag &&
											<Checkbox
												// isChecked={selectedCategoriesOptions.includes(option.value)}
												isChecked={selectedFile.includes(td.filename)}
												// onChange={() => setSelectFile(td.filename)}
												onChange={() =>
													setSelectFile((prev) =>
														prev.includes(td.filename)
															? prev.filter((item) => item !== td.filename)
															: [...prev, td.filename]
													)
												}
											/>
										}
									</Center>

									{/* Preview the PDF file */}
									{/* console.log(`${file_download_url}/${td.filename}`); */}
									{/* {td.file_type === 'pdf' && (
										<Box h="200px">
											<iframe
												// src={`${file_download_url}/${td.filename}`} 
												// src="blob:https://bdconflict.watch/api/v1/makepdf/download/bdcw/doc_bdef637c775511ef83cc0242ac120004.pdf"
												title="PDF Preview"
												width="100%"
												height="100%"
												allow="autoplay; encrypted-media"
												allowFullScreen
												style={{ border: "none" }}
											/>
										</Box>
									)} */}

									{/* Preview for Image files */}
									{/* {td.file_type === 'image' && (
										<Box w="100%" mx="auto">
											<img
												src={td.file}
												alt={td.file_type}
												style={{ maxWidth: '100%', height: 'auto' }}
											/>
										</Box>
									)} */}

									{/* Preview for Video files */}
									{/* {td.file_type === 'video' && (
										<Center>
											<video
												controls
												width="100%"
												src={td.file}
											>
												Your browser does not support the video tag.
											</video>
										</Center>
									)} */}
								</Box>
							</Flex>
						))
					) : (
						<Text textAlign="center" my={4}>
							No data found for the current search/filter.
						</Text>
					)}

					{/* Pagination controls */}
					<Stack gap={[2, 5]} marginY={3} mt={4}
						direction={['column', 'row']}
						justify={"space-between"}
					>
						<FormControl width={["100%", "40%", "30%", "20%"]}>
							<Select
								placeholder="Select items per page"
								size="md"
								variant="filled"
								onChange={(e) => {
									setItemsPerPage(Number(e.target.value));
									setCurrentPage(1);  // Reset to the first page when items per page changes
								}}
							>
								<option value={10}>10</option>
								<option value={20}>20</option>
								<option value={50}>50</option>
								<option value={100}>100</option>
							</Select>
						</FormControl>

						<HStack justify="center" width={["100%", "60%", "40%", "30%"]}>
							<Button
								onClick={() => handlePageChange(currentPage - 1)}
								isDisabled={currentPage === 1}
							>
								Previous
							</Button>

							<Text>
								{/* Page {currentPage} of {totalPages} */}
								Page {currentPage}
							</Text>

							<Button
								onClick={() => handlePageChange(currentPage + 1)}
							// isDisabled={currentPage === totalPages}
							>
								Next
							</Button>
						</HStack>
					</Stack>
				</Box>
			</Container >

			<Footer />

			{/* Modal for editing document */}
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit Document</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormControl >
							<FormLabel>Add Date</FormLabel>
							<Input
								// type='datetime-local'
								type='date'
								value={date}
								onChange={handleDateChange}
							/>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Location</FormLabel>
							{/* <Textarea defaultValue={editData?.description} /> */}
							<Select
								placeholder={"Select Location"}
								size={["md", "md", "lg"]}
								variant="filled"
								focusBorderColor="blue.500"
								borderRadius="md"
								boxShadow="sm"
								// required
								onChange={handleLocationChange}
								value={selectedLocationOption}
							>
								{optionsLocation.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Select>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Source URL</FormLabel>
							<Input type="url"
								value={url}
								onChange={handleURLChange}
							/>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Categories</FormLabel>
							{categoriesOptions.map((option) => (
								<Box key={option.value}>
									<Checkbox
										isChecked={selectedCategoriesOptions.includes(option.value)}
										onChange={() => handleCheckboxChange(option.value)}
									>
										{option.label}
									</Checkbox>
								</Box>
							))}
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Source Type Description</FormLabel>
							{/* <Textarea defaultValue={editData?.description} /> */}
							<Select
								// placeholder={sourceTypeInputPlaceholderLinkToPdfPage}
								size={["md", "md", "lg"]}
								variant="filled"
								focusBorderColor="blue.500"
								borderRadius="md"
								boxShadow="sm"
								// required
								onChange={handleSourceTypeChange}
								value={selectedSourceTypeOption}
							>
								{options.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Select>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Add Comments</FormLabel>
							<Textarea
								type="text"
								value={comment}
								onChange={handleCommentChange}
							/>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Death</FormLabel>
							<Input type="death"
								value={death}
								onChange={handleDeathChange}
							/>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Injury</FormLabel>
							<Input type="injury"
								value={injury}
								onChange={handleInjuryChange}
							/>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Property Value</FormLabel>
							<Input type="propertyValue"
								value={propertyValue}
								onChange={handlePropertyValueChange}
							/>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Number Of Houses</FormLabel>
							<Input type="noOfHouses"
								value={noOfHouses}
								onChange={handleNoOfHousesChange}
							/>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Property Vandalized</FormLabel>
							<Input type="propertyVandalized"
								value={propertyVandalized}
								onChange={handlePropertyVandalizedChange}
							/>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Events</FormLabel>
							{/* <Textarea defaultValue={editData?.description} /> */}
							<Select
								placeholder={"Select Events"}
								size={["md", "md", "lg"]}
								variant="filled"
								focusBorderColor="blue.500"
								borderRadius="md"
								boxShadow="sm"
								// required
								onChange={handleEventsChange}
								value={selectedEventsOption}
							>
								{optionsEvents.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Select>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Review Status</FormLabel>
							{/* <Textarea defaultValue={editData?.description} /> */}
							<Select
								placeholder={"Select Review"}
								size={["md", "md", "lg"]}
								variant="filled"
								focusBorderColor="blue.500"
								borderRadius="md"
								boxShadow="sm"
								// required
								onChange={handleReviewStatusChange}
								value={selectedReviewStatusOption}
							>
								{optionsReviewStatus.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Select>
						</FormControl>

						<FormControl>
							<FormLabel>
								Tags	
							</FormLabel>
							<TagField 
								embedded={true} 
								listSelectedTags={listSelectedTags} 
								listSetSelectedTags={listSetSelectedTags}
							/>
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3}
							// onClick={onClose}
							onClick={handleSubmit}
						>
							Update
						</Button>
						<Button variant="ghost" onClick={onClose}>Cancel</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			
			{/* Modal for showing file document */}
			
			<Modal isOpen={isFileViewOpen} onClose={onFileViewClose} size="full" >
				<ModalOverlay />
				<ModalContent 
					style={{backgroundColor:'transparent'}} 
					w={{ base: '100vw', md: '90vw', lg:'80vw' }} // Force full width on mobile
					maxW="none" // Ensure no max-width limit on mobile
					borderRadius="0" // Remove border-radius on mobile to fit full screen
				>
					<ModalBody style={{width:"100%"}}>
						<FilePreview fileUrl={"https://bdconflict.watch/api/v1/makepdf/downloaddirect/"+curFilename} fileType={curFileType}/>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3}
							// onClick={onClose}
							onClick={onFileViewClose}
						>
							Close
						</Button>
						<Button variant="ghost" onClick={onClose}>Cancel</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			
		</>
	);
};

export default ListDocuments;
