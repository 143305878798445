import { combineReducers } from "redux";
import data, * as fromData from "./data";
import user, * as fromUser from "./login";
import listDocuments, * as fromListDocuments from "./listDocuments";
import dataUpdate, * as fromUpdateData from "./listDocuments";
import fileDownload, * as fromDownloadFile from "./listDocuments";
import archiveData, * as fromDataArchive from "./archive";
import listArchive, * as fromListArchive from "./archive";
import archiveUpdate, * as fromUpdateArchive from "./archive";
import fileDownloadArchive, * as fromDownloadArchiveFile from "./archive";
import listUser, * as fromListUser from "./user_list";
import listTags, * as fromListTags from "./tags";
import tagsUpdate, * as fromUpdateTags from "./tags";


export const rootReducer = combineReducers({
    "data": data,
    "user": user,
    "user_list": listUser,
    "listDocuments": listDocuments,
    "dataUpdate": dataUpdate,
    "fileDownload": fileDownload,
    "archiveData": archiveData,
    "listArchive": listArchive,
    "archiveUpdate": archiveUpdate,
    "fileDownloadArchive": fileDownloadArchive,
    "listTags": listTags,
    "tagsUpdate": tagsUpdate,
});

const reducerFunction = (state, action) => rootReducer(state, action);
export default reducerFunction;


export const selectID = state => fromUser.selectUserID(state.user);
export const selectToken = state => fromUser.selectToken(state.user);
export const selectIsLoggedIn = state => fromUser.selectIsLoggedIn(state.user);
export const selectIsAdmin = state => fromUser.selectIsAdmin(state.user);
export const selectProfile = state => fromUser.selectProfile(state.user);
export const selectUserLoginErrorMessage = state => fromUser.selectUserLoginErrorMessage(state.user);
//export const selectUserList = state => fromUser.selectUserList(state.user);
export const selectUserList = state => fromListUser.selectUserList(state.user_list);

//export const selectUserList = state => fromUser.selectUserList(state.user);
export const selectUpdateMessage = state => fromUser.selectUpdateMessage(state.user);


export const selectData = state => fromData.selectData(state.data);


export const selectListTags = state => fromListTags.selectListTags(state.listTags);
export const selectUpdateTags = state => fromUpdateTags.selectUpdateTags(state.tagsUpdate);


export const selectListDocuments = state => fromListDocuments.selectListDocuments(state.listDocuments.listDocumentsData);
export const selectUpdateData = state => fromUpdateData.selectUpdateData(state.dataUpdate);
export const selectDownloadFile = state => fromDownloadFile.selectDownloadFile(state.fileDownload);


export const selectDataArchive = state => fromDataArchive.selectDataArchive(state.archiveData);

export const selectListArchive = state => fromListArchive.selectListArchive(state.listArchive.listArchiveData);
export const selectUpdateArchive = state => fromUpdateArchive.selectUpdateArchive(state.archiveUpdate);
export const selectDownloadArchiveFile = state => fromDownloadArchiveFile.selectDownloadArchiveFile(state.fileDownloadArchive);


// admin
// export const selectAllUserData = state => fromUserManagement.selectAllUserData(state.userManagement.usersData);
