import axios from "axios";
import {
    requestGetListTags,
    getListTagsSuccess,
    getListTagsFailure,
    requestUpdateTags,
    updateTagsSuccess,
    updateTagsFailure,
    requestCreateTag,
    createTagSuccess,
    createTagFailure,

    // updateSessionExpiry,
} from "../../ActionCreator/tags";

import { tag_update_url, get_tag_list_url, tag_create_url} from "../../../allApiPath";


// export const archiveDataSubmit = (formData, token) => async (dispatch, getState) => {
//     dispatch(requestSubmitArchiveData())

//     axios.post(archive_upload_url, formData,
//         {
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//                 // 'Content-Type': 'application/json',
//                 'Authorization': token,
//             },
//             // body: JSON.stringify(formData),
//         }
//     )
//         .then((response) => {
//             dispatch(submitArchiveDataSuccess(response.data));
//         }, error => {
//             dispatch(submitArchiveDataFailure(error))
//         })
// }


export const getListTagsData = (skip, limit, token) => async (dispatch, getState) => {
    dispatch(requestGetListTags())
    // console.log(token, skip, limit);

    axios.get(get_tag_list_url(skip, limit), {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(getListTagsSuccess(response.data));
        }, error => {
            dispatch(getListTagsFailure(error));
        })
}


// export const downloadArchiveFile = (filename, token) => async (dispatch) => {
//     dispatch(requestFileDownloadArchive())

//     try {
//         const response = await axios.post(file_download_archive_url, {
//             filename: filename,
//         }, {
//             responseType: 'blob', // Important for file download
//             headers: {
//                 // Authorization: `Bearer ${token}`,
//                 'Authorization': token,
//             }
//         });

//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement('a');
//         link.href = url;

//         const fileName = filename.split('/').pop(); // Extract file name from the full path
//         link.setAttribute('download', fileName); // Set the downloaded file's name

//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);

//         dispatch(fileDownloadArchiveSuccess("Download Success"));
//     } catch (error) {
//         console.error('Download error:', error);
//         dispatch(fileDownloadArchiveFailure(error));
//     }
// };


export const tagsUpdateSubmit = (newTags, token) => async (dispatch, getState) => {
    dispatch(requestUpdateTags())

    axios.post(tag_update_url, {
        newTags: newTags,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(updateTagsSuccess(response.data));
        }, error => {
            dispatch(updateTagsFailure(error))
        })
}

export const createTag = (
    name, 
    token,
) => async (dispatch, getState) => {

    dispatch(requestCreateTag())

    axios.post(tag_create_url, {
        name: name,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(createTagSuccess(response.data));
        }, error => {
            dispatch(createTagFailure(error))
        })
}
