import { createBrowserRouter, } from "react-router-dom";

import Main from "../Layouts/Main";
import Home from "../Pages/Home";
import Login from "../Pages/Login";
import Register from "../Pages/Register";
import UserRoute from "./UserRoute";
import LandingPage from "../Pages/LandingPage";
import LinkToPdf from "../Pages/LinkToPdf";
import ListDocuments from "../Pages/ListDocuments";
import ArchiveUpload from "../Pages/ArchiveUpload";
import ListArchive from "../Pages/ListArchive";
import UserList from "../Pages/UserList";
import Tag from "../Pages/Tag";
import AdminRoute from "./AdminRoute";

export const routes = createBrowserRouter([
	{
		path: '/',
		element: <Main />,
		// element: <UserRoute><Main /></UserRoute>,
		// errorElement: <ErrorPage></ErrorPage>,
		children: [
			{
				path: '/',
				// loader: () => fetch('https://re-books-server.vercel.app/'),
				element: <LandingPage />,
			},
			{
				path: '/home',
				element: <UserRoute><Home /></UserRoute>,
			},
			{
				path: '/report',
				element: <UserRoute><LinkToPdf /></UserRoute>,
			},
//			{
//				path: '/library',
//				element: <UserRoute><ListDocuments /></UserRoute>,
//			},
			{
				path: '/archive_upload',
				element: <UserRoute><ArchiveUpload /></UserRoute>,
			},
			{
				path: '/archive_list',
				element: <UserRoute><ListArchive /></UserRoute>,
			},
			{
				path: '/user_list',
				element: <UserRoute><AdminRoute><UserList /></AdminRoute></UserRoute>,
			},
//			{
//				path: '/tag',
//				element: <UserRoute><AdminRoute><Tag /></AdminRoute></UserRoute>,
//			},
		]
	},
	// {
	// 	path: '/dashboard',
	// 	element: <AdminRoute><DashboardLayout /></AdminRoute>,
	// 	children: [
	// 		{
	// 			path: '/dashboard',
	// 			element: <Dashboard />
	// 		},
	// 		{
	// 			path: '/dashboard/withdraw/history/:id',
	// 			element: <WithdrawalHistory />
	// 		},
	// 	]
	// },
	{
		path: '/login',
		element: <Login />
	},
	// {
	// 	path: '/admin/login',
	// 	element: <AdminLogin />
	// },
	{
		path: '/register',
		element: <Register />
	},
	{
		path: '/tag',
		element: <Tag />
	},
	{
		path: '/library',
		element: <ListDocuments />,
	},
//	{
//		path: '/user_list',
//		element:<UserList />,
//	},
//	{
//		path: '/report',
//		element: <LinkToPdf />,
//	},
	
//	{
//						path: '/user_list',
//						element:<UserList />,
//					}
]);