import axios from "axios";
import {
    requestSubmitData,
    submitDataSuccess,
    submitDataFailure,
    requestSubmitAddLinksData,
    submitAddLinksDataSuccess,
    submitAddLinksDataFailure,
    // updateSessionExpiry,
} from "../../ActionCreator/home";

import { add_links_data_url, data_url } from "../../../allApiPath";


export const dataSubmit = (formData, token) => async (dispatch, getState) => {
    dispatch(requestSubmitData())

    axios.post(data_url, formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Content-Type': 'application/json',
                'Authorization': token,
            },
            // body: JSON.stringify(formData),
        }
    )
        .then((response) => {
            dispatch(submitDataSuccess(response.data));
        }, error => {
            dispatch(submitDataFailure(error))
        })
}


export const addLinksDataSubmit = (date, url, selectedCategoriesOptions, selectedSourceTypeOption, comment, token) => async (dispatch, getState) => {
    dispatch(requestSubmitAddLinksData())

    axios.post(add_links_data_url, {
        date: date,
        source_url: url,
        categories: selectedCategoriesOptions,
        source_description: selectedSourceTypeOption,
        source_type: selectedSourceTypeOption,
        comment: comment,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            // console.log(response.data);
            dispatch(submitAddLinksDataSuccess(response.data));
            // dispatch(updateSessionExpiry(nextDate));
        }, error => {
            dispatch(submitAddLinksDataFailure(error))
        })
}
